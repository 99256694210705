body{
  background-repeat: no-repeat;
  background-size: cover;
}

.Bloc_app{
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  background-color: #FBFBFB;
  padding: 50px 0;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}

@media screen and (max-width: 1400px) {
  .Bloc_app {
    width: 60%;
  }
}

@media screen and (max-width: 1200px) {
  .Bloc_app {
    width: 80%;
  }
}

@media screen and (max-width: 700px) {
  .Bloc_app {
    width: 100%;
  }
}

.loader-container, .popup{
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

img {
  width: 100px;
}

strong{
  text-align: center;
}

.felicitations{
  font-size: 32px;
  margin-top: 40px;
  margin-bottom: 20px;
}

.red {
  text-align: center;
  margin-bottom: 60px;
  color: #AD0B15;
}

.text{
  width: 70%;
}

.validation{
  margin-top: 40px;
}

p{
  text-align: center;
  font-size: 20px;
}

.Form{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 500px;
  max-width: 100%;
}

.Form_label{
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  width: 100%;
}

.Form_data{
  margin: 10px 0;
}

label{
  margin-bottom: 8px;
}

input{
  width: calc(100% - 26px);
  height: 56px;
  border: 2px solid #CCCCCC;
}

@media screen and (max-width: 1200px){
  .indication, .Form_data {
    width: 80% !important;
    margin-right: auto;
    margin-left: auto;
  }
}

@media screen and (max-width: 900px) {
  .indication, .Form_data {
    width: 70% !important;
    margin-right: auto;
    margin-left: auto;
  }
}

@media screen and (max-width: 532px){
  .Form {
    width: 90%;
  }
}

input{ 
  padding-left: 20px;
}

::value {
  color: #798A99;
  padding-left: 20px;
}

.data{
  width: 18px;
  height: 18px;
  border: 2px solid #CCCCCC;
}

.Form_data{
  display: flex;
  width: 100%;
}

.Form_data label{
  font-size: 15px;
}

input[type='checkbox'] {
  margin-top: 0;
}

.button{
  background: #3A74B5;
  border: none;
  padding: 12px;
  border-radius: 5px;
  box-shadow: 0px 4px 0px 0px #2f5f96;
  cursor: pointer;
  margin-top: 20px;
  color: white;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 22px;
  text-decoration: none;
  width: auto;
  line-height: 38px;
  height: 60px;
}

.Form_indication{
  margin-top: 40px;
  width: 100%;
}

.indication{
  color: #898989;
  font-size: 15px;
  text-align: start;
}

.mentions{
  font-size: 15px;
  text-decoration: underline;
  margin-top: 50px;
}

.loader, .popup_error{
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translateX(-50%);
}

.popup_error{
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 50px;
  padding-left: 20px;
  margin-top: -10%;
  padding-right: 20px;
}

.error{
  margin-top: 40px;
}

.button {
  transition: all ease-in-out 0.25s;
}

.button:hover {
  opacity: 0.7;
}

select {
  padding-left: 10px;
  font-weight: bold;
  width: 100%;
  color: black;
  height: 56px;
  border: 2px solid #CCCCCC;
}